import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const UserCircle = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#62717C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83325 7.91683C5.83325 5.61564 7.69873 3.75016 9.99992 3.75016C12.3011 3.75016 14.1666 5.61564 14.1666 7.91683C14.1666 10.218 12.3011 12.0835 9.99992 12.0835C7.69873 12.0835 5.83325 10.218 5.83325 7.91683Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 0.833496C4.93731 0.833496 0.833252 4.93755 0.833252 10.0002C0.833252 15.0628 4.93731 19.1668 9.99992 19.1668C15.0625 19.1668 19.1666 15.0628 19.1666 10.0002C19.1666 4.93755 15.0625 0.833496 9.99992 0.833496ZM2.49992 10.0002C2.49992 5.85803 5.85778 2.50016 9.99992 2.50016C14.1421 2.50016 17.4999 5.85803 17.4999 10.0002C17.4999 11.8511 16.8294 13.5454 15.7181 14.8535C14.9546 13.9263 13.7969 13.3335 12.5 13.3335H7.49996C6.20298 13.3335 5.04525 13.9263 4.28179 14.8535C3.17042 13.5454 2.49992 11.8511 2.49992 10.0002Z"
      />
    </svg>
  );
};
