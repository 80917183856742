import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const StickerCircle = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#62717C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.81493 0.833496C6.18757 0.833496 2.54186 3.15501 1.21158 6.57128C1.08948 6.88486 1.02842 7.04165 1.06311 7.17286C1.09302 7.28602 1.17204 7.38407 1.27625 7.43736C1.39709 7.49914 1.59218 7.46823 1.98235 7.40641C2.45834 7.331 2.94642 7.29183 3.44368 7.29183C8.56019 7.29183 12.708 11.4396 12.708 16.5561C12.708 16.9947 12.6775 17.4261 12.6185 17.8484C12.5617 18.256 12.5332 18.4598 12.5985 18.5803C12.6553 18.685 12.7551 18.7609 12.8712 18.7877C13.0048 18.8185 13.1671 18.7481 13.4918 18.6074C16.8187 17.1656 19.0792 13.7659 19.0792 10.0978C19.0792 4.98126 14.9315 0.833496 9.81493 0.833496Z" />
      <path d="M9.95121 17.8288C10.2048 18.0822 10.3317 18.209 10.4755 18.2257C10.592 18.2393 10.7319 18.1914 10.8156 18.1093C10.9189 18.0079 10.9395 17.8452 10.9807 17.5198C11.0207 17.2042 11.0413 16.8825 11.0413 16.5561C11.0413 12.3601 7.63972 8.9585 3.44368 8.9585C3.11556 8.9585 2.79227 8.9793 2.47508 9.01967C2.14974 9.06107 1.98707 9.08177 1.88572 9.18521C1.80366 9.26894 1.75584 9.40892 1.76949 9.52536C1.78636 9.66918 1.91318 9.79592 2.16682 10.0494L9.95121 17.8288Z" />
    </svg>
  );
};
