import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const CopyIcon = (props: Props) => {
  return (
    <svg fill="#13171A" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.1458 4.05118C16.5289 4.00078 15.7366 4 14.6 4H7.5C6.94772 4 6.5 3.55229 6.5 3C6.5 2.44772 6.94772 2 7.5 2L14.6428 2C15.7266 1.99999 16.6007 1.99998 17.3086 2.05782C18.0375 2.11737 18.6777 2.24318 19.27 2.54497C20.2108 3.02433 20.9757 3.78924 21.455 4.73005C21.7568 5.32234 21.8826 5.96253 21.9422 6.69138C22 7.39926 22 8.27342 22 9.35722V16.5C22 17.0523 21.5523 17.5 21 17.5C20.4477 17.5 20 17.0523 20 16.5V9.4C20 8.26339 19.9992 7.47108 19.9488 6.85424C19.8994 6.24907 19.8072 5.90138 19.673 5.63803C19.3854 5.07354 18.9265 4.6146 18.362 4.32698C18.0986 4.19279 17.7509 4.10062 17.1458 4.05118Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.16146 5.5H14.3385C14.8657 5.49998 15.3205 5.49997 15.695 5.53057C16.0904 5.56287 16.4836 5.63419 16.862 5.82698C17.4265 6.1146 17.8854 6.57354 18.173 7.13803C18.3658 7.51641 18.4371 7.90963 18.4694 8.30497C18.5 8.67955 18.5 9.13432 18.5 9.66148V17.8385C18.5 18.3657 18.5 18.8204 18.4694 19.195C18.4371 19.5904 18.3658 19.9836 18.173 20.362C17.8854 20.9265 17.4265 21.3854 16.862 21.673C16.4836 21.8658 16.0904 21.9371 15.695 21.9694C15.3205 22 14.8657 22 14.3385 22H6.16148C5.63432 22 5.17955 22 4.80497 21.9694C4.40963 21.9371 4.01641 21.8658 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2.13419 19.9836 2.06287 19.5904 2.03057 19.195C1.99997 18.8205 1.99998 18.3657 2 17.8385V9.66146C1.99998 9.13431 1.99997 8.67954 2.03057 8.30497C2.06287 7.90963 2.13419 7.51641 2.32698 7.13803C2.6146 6.57354 3.07354 6.1146 3.63803 5.82698C4.01641 5.63419 4.40963 5.56287 4.80497 5.53057C5.17955 5.49997 5.63431 5.49998 6.16146 5.5Z" />
    </svg>
  )
}