import useAuth from "@/hooks/useAuth";
import NavigationItem from "./NavigationItem";
import { isSponsor, isSuperAdmin } from "@/utils/auth";

const Navigation = () => {
  // TODO::Implement superadmin
  const { user } = useAuth();
  const superAdmin = isSuperAdmin(user);
  const sponsor = isSponsor(user);

  return (
    <div>
      <div className="pb-8">
        <img src="/images/logo.svg" alt="Main logo" />
      </div>

      <div className="flex flex-col">
        {superAdmin && <NavigationItem to="/" name="Ana səhifə" icon="home" />}
        {superAdmin && (
          <NavigationItem to="/users" name="İstifadəçilər" icon="users" />
        )}
        {superAdmin && (
          <NavigationItem to="/subscription-quizes" name="Subscription quizes" icon="currency" />
        )}
        {superAdmin && <NavigationItem to="/files" name="Files" icon="users" />}
        {superAdmin && (
          <NavigationItem to="/files" name="Files" icon="file04" />
        )}
        {superAdmin && (
          <NavigationItem to="/stories" name="Stories" icon="sticker" />
        )}
        {superAdmin && (
          <NavigationItem to="/reports" name="Reports" icon="help-circle" />
        )}
        {superAdmin && (
          <NavigationItem
            to="/versions"
            name="Versiyalar"
            icon="code-circle-02"
          />
        )}
        {sponsor && (
          <>
            <NavigationItem to="/create" name="Yarat" icon="plus-circle" />
            <NavigationItem to="/balance" name="Balans" icon="currency" />
          </>
        )}
        {superAdmin && (
          <NavigationItem
            to="/admin-settings"
            name="Tənzimləmələr"
            icon="settings"
          />
        )}
        <NavigationItem
          to="/settings"
          name="Profil məlumatları"
          icon="user-cirlce"
        />
      </div>
    </div>
  );
};

export default Navigation;
