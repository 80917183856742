import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const File04Icon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#62717C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8324 1.62706C10.8333 1.64693 10.8333 1.65789 10.8333 1.67981L10.8333 5.36009C10.8333 5.57041 10.8333 5.77523 10.8475 5.9487C10.863 6.13919 10.8997 6.36397 11.015 6.59016C11.1748 6.90376 11.4298 7.15873 11.7434 7.31852C11.9695 7.43376 12.1943 7.47049 12.3848 7.48605C12.5583 7.50022 12.7631 7.5002 12.9734 7.50017L16.6538 7.50017C16.6756 7.50017 16.6865 7.50017 16.7064 7.50112C17.1179 7.52076 17.4796 7.88265 17.4991 8.29418C17.5 8.31403 17.5 8.3205 17.5 8.33345V14.3679C17.5 15.0387 17.5 15.5924 17.4632 16.0434C17.4249 16.5118 17.3428 16.9424 17.1367 17.3468C16.8171 17.974 16.3072 18.4839 15.68 18.8035C15.2755 19.0096 14.8449 19.0917 14.3765 19.13C13.9255 19.1668 13.3719 19.1668 12.7011 19.1668H7.2989C6.6281 19.1668 6.07447 19.1668 5.62348 19.13C5.15507 19.0917 4.72448 19.0096 4.32003 18.8035C3.69283 18.4839 3.18289 17.974 2.86331 17.3468C2.65724 16.9424 2.5751 16.5118 2.53683 16.0434C2.49998 15.5924 2.49999 15.0387 2.5 14.3679V5.6324C2.49999 4.96161 2.49998 4.40797 2.53683 3.95698C2.5751 3.48856 2.65724 3.05798 2.86331 2.65353C3.18289 2.02632 3.69283 1.51639 4.32003 1.19681C4.72448 0.990734 5.15507 0.908597 5.62348 0.870326C6.07447 0.833479 6.62809 0.833487 7.29888 0.833497H10C10.013 0.833492 10.0195 0.833489 10.0394 0.834429C10.4509 0.853895 10.8127 1.21558 10.8324 1.62706Z"
      />
      <path d="M16.2239 5.77205C16.1237 5.83349 16.0013 5.83349 15.7566 5.83349L13.1667 5.83345C12.9333 5.83345 12.8166 5.83345 12.7275 5.78804C12.6491 5.74809 12.5854 5.68435 12.5454 5.60595C12.5 5.51682 12.5 5.40014 12.5 5.1668L12.5 2.57686C12.5 2.33218 12.5 2.20983 12.5614 2.10961C12.6482 1.96808 12.8526 1.88347 13.0141 1.92226C13.1284 1.94974 13.2081 2.02952 13.3675 2.18909L16.1444 4.96603C16.304 5.12544 16.3838 5.20514 16.4112 5.31944C16.45 5.48086 16.3654 5.68528 16.2239 5.77205Z" />
    </svg>
  );
};
