import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const CodeCircle02 = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#62717C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 0.833496C4.93731 0.833496 0.833252 4.93755 0.833252 10.0002C0.833252 15.0628 4.93731 19.1668 9.99992 19.1668C15.0625 19.1668 19.1666 15.0628 19.1666 10.0002C19.1666 4.93755 15.0625 0.833496 9.99992 0.833496ZM8.92251 6.42275C9.24794 6.09731 9.24794 5.56968 8.92251 5.24424C8.59707 4.9188 8.06943 4.9188 7.744 5.24424L5.244 7.74424C4.91856 8.06968 4.91856 8.59731 5.244 8.92275L7.744 11.4228C8.06943 11.7482 8.59707 11.7482 8.92251 11.4228C9.24794 11.0973 9.24794 10.5697 8.92251 10.2442L7.01176 8.3335L8.92251 6.42275ZM12.2558 8.57757C11.9304 8.25214 11.4028 8.25214 11.0773 8.57757C10.7519 8.90301 10.7519 9.43065 11.0773 9.75608L12.9881 11.6668L11.0773 13.5776C10.7519 13.903 10.7519 14.4306 11.0773 14.7561C11.4028 15.0815 11.9304 15.0815 12.2558 14.7561L14.7558 12.2561C15.0813 11.9306 15.0813 11.403 14.7558 11.0776L12.2558 8.57757Z"
      />
    </svg>
  );
};
