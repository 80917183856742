import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const HelpCircle = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#62717C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 0.833496C4.93731 0.833496 0.833252 4.93755 0.833252 10.0002C0.833252 15.0628 4.93731 19.1668 9.99992 19.1668C15.0625 19.1668 19.1666 15.0628 19.1666 10.0002C19.1666 4.93755 15.0625 0.833496 9.99992 0.833496ZM9.08878 6.89285C9.42811 6.69342 9.82708 6.62052 10.215 6.68706C10.6029 6.7536 10.9548 6.95529 11.2083 7.2564C11.4618 7.55752 11.6005 7.93862 11.5999 8.33222L11.5999 8.33346C11.5999 8.72441 11.2957 9.13198 10.721 9.51509C10.459 9.68974 10.1911 9.82428 9.98542 9.9157C9.88376 9.96089 9.8002 9.99425 9.74401 10.0157C9.71907 10.0252 9.69403 10.0343 9.66885 10.0432C9.23279 10.1891 8.99723 10.6607 9.14268 11.097C9.28822 11.5336 9.76015 11.7696 10.1968 11.624L10.3373 11.5731C10.4179 11.5425 10.5296 11.4977 10.6623 11.4387C10.9254 11.3218 11.2825 11.1439 11.6455 10.9018C12.3207 10.4517 13.2662 9.60952 13.2666 8.33422C13.2676 7.5472 12.9902 6.78517 12.4833 6.18307C11.9764 5.58084 11.2726 5.17747 10.4968 5.04438C9.72091 4.9113 8.92298 5.0571 8.24431 5.45596C7.56564 5.85482 7.05003 6.481 6.7888 7.22359C6.63607 7.65775 6.86422 8.13351 7.29838 8.28624C7.73253 8.43897 8.2083 8.21082 8.36103 7.77667C8.49164 7.40537 8.74944 7.09228 9.08878 6.89285ZM9.99991 13.3335C9.53968 13.3335 9.16658 13.7066 9.16658 14.1668C9.16658 14.627 9.53968 15.0001 9.99991 15.0001H10.0082C10.4685 15.0001 10.8416 14.627 10.8416 14.1668C10.8416 13.7066 10.4685 13.3335 10.0082 13.3335H9.99991Z"
      />
    </svg>
  );
};
